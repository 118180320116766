import { useState } from "react";
import sampleData from "../sampleData/sampleData.json";
import DescriptionCard from "./uiFreeDescriptionCard";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../css/tweakpane.css"
import EnvironmentConfig from "./environmentConfig";
import CollectedDataCard from "./collectedDataCard";
import SubmissionDataCard from "./submissionDataCard";

function UIFreesSubmission() {
  const [payload, setPayload] = useState(JSON.stringify(sampleData, null, 4));
  const [artifacts,setArtifacts] = useState([])
  const [parameters, setParameters] = useState({
    org: "shoppers-drug-mart",
    team: "default",
    form: "healthwatch-integration-working-version",
    environment: "sit-eforms.loblaw.ca",
    cuid: null,
    node: null
  });
  return (
    <div>
      <div id="tweakpane"></div>
      <Box sx={{ mt: "40px", flexGrow: 1 }}>
        <Grid container columnSpacing={5}>
          <Grid item xs={5}>
            <DescriptionCard />
          </Grid>
          <Grid item xs={7}>
            <EnvironmentConfig section="ui-free" parameters={parameters} setParameters ={setParameters} payload={payload} setArtifacts={setArtifacts}/>
          </Grid>
          <Grid sx={{ mt: "50px"}} item xs={5}>
            <CollectedDataCard section="ui-free" payload={payload} setPayload={setPayload}/>
          </Grid>
          <Grid sx={{ mt: "50px"}} item xs={7}>
          <SubmissionDataCard section="ui-free" parameters={parameters} artifacts={artifacts}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default UIFreesSubmission;
