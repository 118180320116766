import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JSON"];

function DragDrop(props) {
  const handleFileChange = (file) => {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      props.callback(reader.result);
    };
  };
  return (
    <FileUploader
      handleChange={handleFileChange}
      name="file"
      types={fileTypes}
    />
  );
}

export default DragDrop;
