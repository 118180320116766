import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function harnessDescriptionCard() {
  return (
    <Card sx={{ minWidth: 400 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          UI Free Submission Introduction
        </Typography>
        <br />
        <Typography variant="body2">
          <p>
            UI Free Submission allows a user to submit a form without going through the form UI. This can be helpful when working with PDF generation or testing submission actions on a particular submission node.
          </p>
          <ol>
            <li>Submitting a form with a given payload(JSON format) - You can specify which form by entering the enviroment settings</li>
            <li>To submit the payload aginst a particular form, please specify which submission node you want to submit against.</li>
            <li>Version CUID is an optional parameter. This can be used to select a specific form version to submit the form. To locate the CUID, please look for "cuid" binding in the bottom of the form JSON.</li>
            <li>When submitting a form using UI Free Submission. All the actions(integrations, email, etc..) will be triggered on the corresponding submission node. Please make sure to check this carefully especially when dealing with production environment</li>
          </ol>
        </Typography>
        <CardActions>
        <Button href="https://shoppers-drug-mart-sandbox.sit-eforms.loblaw.ca/#/joey-sandbox/feedback" target="_blank" size="small">Feedback</Button>
      </CardActions>
      </CardContent>
    </Card>
  );
}
