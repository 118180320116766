import { Pane } from "tweakpane";

function TweakPane(payload) {
  const pathBlades = [];
  const pathFolders = [];
  var clearPane;
  const PARAMS = {
    pathJSON: "",
    collectedDataJSON: payload,
  };
  const pane = new Pane({
    container: document.getElementById("tweakpane"),
  });

  const pathsFolder = pane.addFolder({
    title: "Path Details",
    expanded: false,
  });

  const jsonFolder = pane.addFolder({
    title: "Collected Data",
    expanded: false,
  });

  jsonFolder.addMonitor(PARAMS, "collectedDataJSON", {
    multiline: true,
    lineCount: 32,
  });

  document.getElementById("tweakpane").style.display = "block";
  pane.refresh();

  this.clearPane = function () {
    try {
      pathFolders.forEach((folder) => {
        folder.dispose();
      });

      pathBlades.forEach((blade) => {
        blade.dispose();
      });

      pathFolders.length = 0;
      pathBlades.length = 0;
    } catch (e) {
      console.error("Error clearing path info", e);
    }
  };

  this.closePane = function () {
    console.log("close pane")
    pane.dispose()
  };

  this.parseFormInfo = function (onChangeData) {
    var progress = onChangeData.progress;
    this.clearPane();
    // parse each node in the path array, extracting the visited, completed, and active values for each
    const items = progress.path.forEach((item) => {
      if (
        item.visited === undefined ||
        item.completed === undefined ||
        item.active === undefined
      ) {
        return;
      }

      const nodeFolder = pathsFolder.addFolder({
        title: `Node ${item?.node}`,
      });
      pathFolders.push(nodeFolder);

      try {
        const visitedBlade = nodeFolder.addBlade({
          view: "text",
          label: "visited",
          parse: (v) => String(v),
          value: item?.visited,
        });
        const completedBlade = nodeFolder.addBlade({
          view: "text",
          label: "completed",
          parse: (v) => String(v),
          value: item?.completed,
        });
        const activeBlade = nodeFolder.addBlade({
          view: "text",
          label: "active",
          parse: (v) => String(v),
          value: item?.active,
        });
        pathBlades.push(visitedBlade);
        pathBlades.push(completedBlade);
        pathBlades.push(activeBlade);
      } catch (e) {
        console.error(`Error adding blades path info for ${item}`, e);
      }
    });

    // add the blades for path and completion percentages
    const completion = pathsFolder.addBlade({
      view: "slider",
      label: "Complettion Percentage",
      min: 0,
      max: 100,
      parse: (v) => parseInt(v),
      value: progress?.completionPercentage,
    });
    pathBlades.push(completion);

    const pathCompletion = pathsFolder.addBlade({
      view: "slider",
      label: "Path Percentage",
      min: 0,
      max: 100,
      parse: (v) => parseInt(v),
      value: progress?.pathPercentage,
    });
    pathBlades.push(pathCompletion);
    PARAMS.collectedDataJSON = JSON.stringify(
      onChangeData.collectedData,
      null,
      4
    );
    pane.refresh();
  };
}

export default TweakPane;
