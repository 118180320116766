import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function DenseTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
      <caption>This table contains all the artifacts generated on submission. Time to live for those documents is 10 minutes by default</caption>
        <TableHead>
          <TableRow>
            <TableCell><b>Artifact name</b></TableCell>
            <TableCell align="right"><b>Artifact Type</b></TableCell>
            <TableCell ><b>URL</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.artifacts.map((artifact) => (
            <TableRow
              key={artifact.fileName}
            >
              <TableCell component="th" scope="row">
                {artifact.fileName}
              </TableCell>
              <TableCell align="right">{artifact.artifactType}</TableCell>
              <TableCell><a href={artifact.url} target="_blank">{artifact.url}</a></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}