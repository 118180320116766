import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import Editor from "react-simple-code-editor";
import FileSaver from "file-saver";
import DenseTable from "./artifactsTable";
import dataTransformer from "../utils/followupTransformer"
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

const SubmissionDataCard = (props) => {
  const downloadSubmissionJSON = () => {
    var blob = new Blob([submissionPayload], {
      type: "application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, "submissionData");
  };
  const generateFollowUpData = () => {
    var blob = new Blob([JSON.stringify(dataTransformer(JSON.parse(submissionPayload)),null,4)], {
      type: "application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, "followupData");
  };
  const downloadArtifacts = () => {
    var zip = new JSZip();
    var count = 0;
    artifacts.forEach(function(artifact){
      var filename = artifact.fileName;
      var url = artifact.url
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
         if(err) {
            throw err; // or handle the error
         }
         zip.file(filename, data, {binary:true});
         count++;
         if (count == artifacts.length) {
          var d = new Date();
var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " +
d.getHours() + ":" + d.getMinutes();
           zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, `${parameters.form}-${datestring}.zip`);
           });
        }
      });
    });
  }
  var { parameters, setSubmissionPayload, submissionPayload, artifacts, section} = props;
  return (
    <Card sx={{ minWidth: 400 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
        {section !== "ui-free" ? "Submission Data" : "Submission Artifacts"}
        </Typography>
        <br />
        <Typography variant="body2">
          <DenseTable sx={{ mt: "10px", mb: "10px" }} artifacts={artifacts} />
          {section !== "ui-free" ? <Button
            sx={{ mt: "10px", mb: "10px" }}
            onClick={() => {
              downloadSubmissionJSON();
            }}
            variant="contained"
            color="success"
          >
            Download Data
          </Button> : null}
          {section === "ui-free" ? <Button
            sx={{ mt: "10px", mb: "10px" }}
            onClick={() => {
              downloadArtifacts();
            }}
            variant="contained"
            color="success"
          >
            Download Artifacts
          </Button> : null}
          {section !== "ui-free" ? <Button
            sx={{ ml:"20px", mt: "10px", mb: "10px" }}
            onClick={() => {
              generateFollowUpData();
            }}
            variant="contained"
            color="success"
          >
            Generate Follow Up Data
          </Button> : null}
          {section !== "ui-free" ? <Editor
            value={submissionPayload}
            onValueChange={(submissionPayload) =>
              setSubmissionPayload(submissionPayload)
            }
            highlight={(submissionPayload) =>
              highlight(submissionPayload, languages.js)
            }
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              borderRadius: "25px",
              border: "2px solid #CCCCCC",
            }}
          /> : null}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SubmissionDataCard;
