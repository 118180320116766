import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const pages = {
  testHarness: "Test Harness",
  uiFreeSubmission: "UI Free Submission",
};

function ResponsiveAppBar() {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {Object.keys(pages).map((key) => (
              <Link href={key} underline="none">
                <Button
                  key={pages[key]}
                  sx={{ mr: "60px", my: 1, color: "white", display: "block" }}
                >
                  {pages[key]}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
