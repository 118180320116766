import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import launchForm from "../utils/launchForm";
import { submitForm, generateNodes } from "../utils/submissionService";
import { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

const EnvironmentConfig = (props) => {
  var {
    parameters,
    setParameters,
    setArtifacts,
    setSubmissionPayload,
    payload,
    section,
  } = props;
  const handleChange = (event) => {
    setParameters({ ...parameters, [event.target.name]: event.target.value });
  };
  const [submissionNodes, setSubmissionNodes] = useState([]);
  return (
    <Card sx={{ minWidth: 400 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          Environment Settings
        </Typography>
        <br />
        <Typography variant="body2">
          <div>
            <FormControl>
              <InputLabel id="environment-select">Environment</InputLabel>
              <Select
                labelId="environment-select"
                id="environment-select"
                value={parameters.environment}
                label="Environment"
                name="environment"
                onChange={handleChange}
              >
                <MenuItem value="sit-eforms.loblaw.ca">
                  sit-eforms.loblaw.ca
                </MenuItem>
                <MenuItem value="eforms.loblaw.ca">eforms.loblaw.ca</MenuItem>
                <MenuItem value="sandbox.sit-eforms.loblaw.ca">
                  sandbox.sit-eforms.loblaw.ca
                </MenuItem>
                <MenuItem value="staging.eforms.loblaw.ca">
                staging.eforms.loblaw.ca
                </MenuItem>
                <MenuItem value="formhero.cloud">formhero.cloud</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ ml: "40px", width: "250px" }}
              required
              id="outlined-required"
              label="Organization"
              name="org"
              onChange={handleChange}
              value={parameters.org}
            />
            <TextField
              sx={{ ml: "40px" }}
              required
              id="outlined-required"
              label="Team"
              name="team"
              onChange={handleChange}
              value={parameters.team}
            />
            <div>
              <TextField
                sx={{ mt: "30px", width: "350px" }}
                required
                id="outlined-required"
                label="Form"
                name="form"
                onChange={handleChange}
                value={parameters.form}
              />
              {section === "ui-free" && submissionNodes.length > 0 ? (
                <FormControl sx={{ mt: "30px", ml: "40px", width: "200px" }}>
                  <InputLabel id="submission-node-select">Node</InputLabel>
                  <Select
                    labelId="submission-node-select"
                    id="submission-node-select"
                    value={parameters.node}
                    label="Node"
                    name="node"
                    onChange={handleChange}
                  >
                    {submissionNodes.map((node) => {
                      return (
                        <MenuItem value={node.uuid}>{node.nodeLabel}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
              {section === "ui-free" ? (
                <TextField
                  sx={{ mt: "30px", ml: "40px", width: "200px" }}
                  id="outlined-required"
                  label="Version CUID"
                  name="cuid"
                  onChange={handleChange}
                  value={parameters.cuid}
                />
              ) : null}
              {section !== "ui-free" ? (
                <Button
                  sx={{ mt: "40px", ml: "40px" }}
                  onClick={() => {
                    launchForm(window.formhero, parameters, payload, {
                      setSubmissionPayload,
                      setArtifacts,
                    });
                  }}
                  variant="contained"
                  color="success"
                >
                  Launch Form
                </Button>
              ) : null}
              {section === "ui-free" ? (
                <Button
                  sx={{ mt: "40px", ml: "40px" }}
                  onClick={() => {
                    generateNodes(
                      window.formhero,
                      parameters,
                      setSubmissionNodes,
                      setParameters
                    );
                  }}
                  variant="contained"
                  color="success"
                >
                  List Submission nodes
                </Button>
              ) : null}
              {section === "ui-free" && parameters.node ? (
                <Button
                  sx={{ mt: "40px", ml: "20px" }}
                  onClick={() => {
                    submitForm(window.formhero, parameters, payload, {
                      setArtifacts,
                    });
                  }}
                  variant="contained"
                  color="success"
                >
                  Submit Form
                </Button>
              ) : null}
            </div>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EnvironmentConfig;
