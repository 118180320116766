const submitForm = async function UIFreeSubmission(
  formhero,
  args,
  payload,
  setResult
) {
  if (!args.node) {
    alert("Submission node is required");
    return;
  }
  var submissionRequest = {
    organization: args.org,
    team: args.team,
    smartForm: args.form,
    mode: "prod",
    smartFormCuid: args.cuid,
    currentNode: args.node,
    data: JSON.parse(payload),
  };

  formhero.setFormHeroHost(args.environment);
  return formhero
    .submit(submissionRequest)
    .then(function (result) {
      var finalList = [];
      result.artifactManifest.artifacts.forEach((item) => {
        let artifactId = item.id;
        if (artifactId) {
          let [artifactType, sessionId, artifactUuid, fileName] =
            artifactId.split("::");
          let url = [
            `https://services.${args.environment}/submissions/session-resource`,
            artifactType,
            sessionId,
            artifactUuid,
            encodeURIComponent(fileName),
          ].join("/");
          finalList.push({
            fileName,
            url,
            artifactType,
          });
        }
      });
      setResult.setArtifacts(finalList);
    })
    .catch((error) => {
      setResult.setArtifacts([]);
      alert(
        "Can not complete UI Free Submission. Please check the configurations"
      );
    });
};

function generateNodes(formhero, args, setSubmissionNodes, setParameters) {
  formhero.setFormHeroHost(args.environment);
  formhero
    .getFormSubmissionNodes(args.org, args.team, args.form)
    .then((nodes) => {
      if (nodes.length == 0) {
        setSubmissionNodes(nodes);
        setParameters({ ...args, node: null });
        alert("No submission node found for the form");
      } else {
        setParameters({ ...args, node: null });
        setSubmissionNodes(nodes);
      }
    })
    .catch((error) => alert("Can not generate nodes using this configuration"));
}

export { submitForm, generateNodes };
