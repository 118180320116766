import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DragDrop from "./dragAndDrop";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import Editor from "react-simple-code-editor";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import FileSaver from "file-saver";
import dataTransformer from "../utils/followupTransformer"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CollectedDataCard = (props) => {
  const downloadJSON = () => {
    var blob = new Blob([payload], { type: "application/json;charset=utf-8" });
    FileSaver.saveAs(blob, "collectedData");
  };
  const generateFollowUpData = () => {
    var blob = new Blob([JSON.stringify(dataTransformer(JSON.parse(payload)),null,4)], {
      type: "application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, "followupData");
  };
  var {setPayload, payload, section} = props;
  return (
    <Card sx={{ minWidth: 400 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          Collected Data JSON
        </Typography>
        <br />
        <Typography variant="body2">
          <DragDrop callback={setPayload} />
          <Button sx={{ mt: "10px", mb: "10px" }}
            onClick={() => {
              downloadJSON();
            }}
            variant="contained"
            color="success"
          >
            Download
          </Button>
          {section !== "ui-free" ? <Button
            sx={{ ml:"20px", mt: "10px", mb: "10px" }}
            onClick={() => {
              generateFollowUpData();
            }}
            variant="contained"
            color="success"
          >
            Generate Follow Up Data
          </Button> : null}
          <Editor
            value={payload}
            onValueChange={(payload) => setPayload(payload)}
            highlight={(payload) => highlight(payload, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              borderRadius: "25px",
              border: "2px solid #CCCCCC",
            }}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CollectedDataCard;
