import ReactDOM from "react-dom/client";
import TestHarness from "./UIComponent/harness.js"
import UIFreesSubmission from "./UIComponent/uifreeSubmission";
import Banner from "./UIComponent/banner"
import ResponsiveAppBar from "./UIComponent/navBar";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
function App() {
  return (
    <div>
    <Banner/>
    <ResponsiveAppBar />
    <Router>
      <Routes>
          <Route path="/uiFreeSubmission" element={<UIFreesSubmission />}/>
          <Route path="/testHarness" element={<TestHarness />}/>
          <Route exact path="/" element={<TestHarness />}/>
      </Routes>
    </Router>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);