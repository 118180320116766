import uniqid from 'uniqid';

export default function followupDataTransform(initialPayload){
    let followUpData = {
        "form_0" : initialPayload
    }
    followUpData["patient.selectedSSFTxId"] = uniqid()
    followUpData["ssf.rxNumber"] = uniqid()
    followUpData["ssf.txNumber"] = uniqid()
    followUpData["service.ssfID"] = uniqid()
    
    return followUpData

}