import "../css/banner.css";
import banner from "../img/logo.png";

const Banner = () => {
  return (
    <div className="banner">
      <img src={banner} alt="Banner" />
      <h2>DCS Tooling Portal</h2>
    </div>
  );
};

export default Banner;
