import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function harnessDescriptionCard() {
  return (
    <Card sx={{ minWidth: 400 }} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          Test Harness Introduction
        </Typography>
        <br />
        <Typography variant="body2">
          <p>
            Form development of any complexity requires tools and an approach
            for managing test-cases associated with the form. DCS harness make use of formhero.js to supply complex data payloads
            to a form. It currently supports:
          </p>
          <ol>
            <li>Launching a form with a given payload(JSON format) - You can specify which form by entering the enviroment settings</li>
            <li>Download collected data at any point of the form</li>
            <li>Modify form path and collected data per panel scroll</li>
            <li>Access to submission data and artifacts</li>
          </ol>
        </Typography>
        <CardActions>
        <Button href="https://shoppers-drug-mart-sandbox.sit-eforms.loblaw.ca/#/joey-sandbox/feedback" target="_blank" size="small">Feedback</Button>
      </CardActions>
      </CardContent>
    </Card>
  );
}
