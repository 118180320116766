import FileSaver from "file-saver";
import TweakPane from "./tweakpane"
const launchForm = (formhero, args, payload,setResult) => {
  formhero.setFormHeroHost(args.environment);
  formhero.useHttps(true);
  var pane = new TweakPane(payload)
  try {
    var payload = JSON.parse(payload);
    formhero
      .loadForm(
        {
          form: args.form,
          organization: args.org,
          team: args.team,
          viewMode: "modal",
          saveButtonMarkup: `<div class="saveButton" style="cursor: pointer;width: 75px; font-weight:500; color: #fff; font-family:Roboto; border-radius:4px; height: 45px; position: absolute; right: 200px; top: -22px; background-color: #2e7d32;  display: flex; flex-direction: column; justify-content: center;font-weight: 300; font-size: 14px; text-align: center; padding: 0 10px;"><i class="fa fa-save"></i>Close Form</div>`,
          cancelButtonMarkup: `<div class="saveButton" style="cursor: pointer;width: 75px; font-weight:500; color: #fff; font-family:Roboto; border-radius:4px; height: 45px; position: absolute; right: 50px; top: -22px; background-color: #2e7d32;  display: flex; flex-direction: column; justify-content: center;font-weight: 300; font-size: 14px; text-align: center; padding: 0 10px;"><i class="fa fa-save"></i>Download Data</div>`,
          onCloseFn: function (state, data) {
            //This is closing the form
            if (state == "close-and-save") {
              if(pane){
                pane.closePane()
              }
            }
            //This is download option 
            else if (state == "cancel-without-save") {
              var blob = new Blob([JSON.stringify(data,null,4)], {type: "application/json;charset=utf-8"});
              FileSaver.saveAs(blob, "collectedData");
            }
          },
          onStatusFn: function (status) {
            console.log("Form State: ", status);
            try {
              pane.parseFormInfo(status);
            } catch (e) {
              console.log("Error in onStatusFn", e);
            }
          },
        },
        payload
      )
      .then(
        function (successResult) {
          setResult.setSubmissionPayload(JSON.stringify(successResult.collectedData,null,4))
          setResult.setArtifacts(successResult.artifacts)
          if(pane){
            pane.closePane()
          }
          document.getElementById("tweakpane").style.display = "none";
        },
        function (cancelledResult) {
          if(pane){
            pane.closePane()
          }
          document.getElementById("tweakpane").style.display = "none";
        }
      )
      .catch(function (exceptionResult) {
        if(pane){
          pane.closePane()
        }
        alert("On Exception" + exceptionResult);
      });
  } catch (error) {
    if(pane){
      pane.closePane()
    }
    alert(error);
    return;
  }
};

export default launchForm;