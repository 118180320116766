import { useState } from "react";
import sampleData from "../sampleData/sampleData.json";
import DescriptionCard from "./harnessDescriptionCard";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../css/tweakpane.css"
import EnvironmentConfig from "./environmentConfig";
import CollectedDataCard from "./collectedDataCard";
import SubmissionDataCard from "./submissionDataCard";

function TestHarness() {
  const [payload, setPayload] = useState(JSON.stringify(sampleData, null, 4));
  const [submissionPayload,setSubmissionPayload] = useState("")
  const [artifacts,setArtifacts] = useState([])
  const [parameters, setParameters] = useState({
    org: "shoppers-drug-mart",
    team: "default",
    form: "healthwatch-integration-working-version",
    environment: "sit-eforms.loblaw.ca"
  });
  return (
    <div>
      <div id="tweakpane"></div>
      <Box sx={{ mt: "40px", flexGrow: 1 }}>
        <Grid container columnSpacing={5}>
          <Grid item xs={5}>
            <DescriptionCard />
          </Grid>
          <Grid item xs={7}>
            <EnvironmentConfig payload={payload} parameters={parameters} setParameters ={setParameters} setSubmissionPayload={setSubmissionPayload} setArtifacts={setArtifacts}/>
          </Grid>
          <Grid sx={{ mt: "50px"}} item xs={5}>
            <CollectedDataCard payload={payload} setPayload={setPayload}/>
          </Grid>
          <Grid sx={{ mt: "50px"}} item xs={7}>
          <SubmissionDataCard artifacts={artifacts} submissionPayload={submissionPayload} setSubmissionPayload={setSubmissionPayload}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default TestHarness;
